import React, { ReactNode, useEffect, useState } from "react";
import "./MyApplications.css";
import { MyApplication } from "../admin/types";
import { isAccessTokenValid } from "../utils/authUtils";
import { getMyApplications } from "../services/applicationsService";
import { getSetting } from "../services/settingsService";

export const STAGE_SUBNOMINATIONS_IDS = [
  "19e35936-4765-4633-aaea-d5d79caa29e3",
  "21349d39-a17d-4683-ac7b-8e36c25484b9",
  "3ad74b24-c0df-4a76-9871-6292d77e2d85",
  "50bb5612-a134-4fdc-b318-ff3c1753608a",
  "542d621b-95d7-4a6f-b30f-56276d8193d2",
  "63177056-881e-4e32-8995-9d11b90a33af",
  "668d5e54-8869-4100-8a93-d062449de066",
  "6c8eaf49-467f-4e40-9ea7-b49889c29dc0",
  "7d4054c2-11d2-4540-a8bf-8de1679f2e70",
  "86d2015b-b7b0-4380-98df-aed3ba34f7c0",
  "9d809a57-ed2d-4feb-ab64-c07af49b22c1",
  "a147fbef-50ab-4843-9f53-630a6bc81fba",
  "c9125566-72ac-4685-9f40-15f738eb13d8",
  "d5be44bf-f707-4fa3-9cc6-1ac4c7ec59dd",
  "edac0c7d-334b-4d1d-838b-805f37280b0a",
  "79f372f5-95ce-4c5c-8e25-51b037774e75",
  "644d266f-7051-47b8-8b9b-b87c1bd1e375",
];

function MyApplications() {
  const [applicationsData, setApplicationData] = useState<MyApplication[]>();
  const [showAccepted, setShowAccepted] = useState<boolean>(false);
  const [showAcceptedHall, setShowAcceptedHall] = useState<boolean>(false);

  const getConfigValue = async (key: string) => {
    const value = await getSetting(key);
    return value;
  };

  const getShowAccepted = async () => {
    const value = await getConfigValue("showAccepted");
    setShowAccepted(value as unknown as boolean);
  };
  const getShowAcceptedHall = async () => {
    const value = await getConfigValue("showAcceptedHall");
    setShowAcceptedHall(value as unknown as boolean);
  };

  useEffect(() => {
    async function fetchPage() {
      try {
        const applications = await getMyApplications();
        setApplicationData(applications);
      } catch (error) {
        // Handle error
        console.error("Error fetching pages:", error);
      }
    }

    fetchPage();
    getShowAccepted();
    getShowAcceptedHall();
    // eslint-disable-next-line
  }, []);

  const getStatusName = (status: number) => {
    switch (status) {
      case 2:
        return "На рассмотрении";
      case 1:
        return "Не хватает материалов";
      case 4:
        return "Принята";
      case 3:
        return "Отклонена";

      default:
        return "Новая";
    }
  };

  const getApplicationCard = (application: MyApplication): ReactNode => {
    let showAcceptedValue = showAccepted;
    if (application.subNominationId) {
      //если заявка не сценическая, то настройку показывать ли прошедшие берем другую
      if (
        STAGE_SUBNOMINATIONS_IDS.indexOf(application.subNominationId) === -1
      ) {
        showAcceptedValue = showAcceptedHall;
      }
    }
    const state = showAcceptedValue
      ? application.state
      : application.state === 4 || application.state === 3
      ? 2
      : application.state;
    return (
      <div
        className={`container-v application-card application-${state}`}
        key={`application-card-${application.applicationId}`}
        // onClick={() => {
        //   if (
        //     application.subNomination.nomination?.nominationId &&
        //     [
        //       "409c0531-728b-41d9-bd9a-5b9dcfe647e4",
        //       "fa0a8ff8-58e7-4d01-b829-c5bab20eb2b5",
        //       "edfba3e0-bc93-4c56-a14a-8a171d79c0a0",
        //     ].indexOf(application.subNomination.nomination?.nominationId) !== -1
        //   ) {
        //     window.location.href = `/apply?nominationId=${application.subNomination.nomination?.nominationId}&applicationId=${application.applicationId}`;
        //   }
        // }}
      >
        <p
          className="container application-text"
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <strong>
            Заявка
            {`${
              application.applicationDate
                ? " от " +
                  new Date(application.applicationDate).toLocaleDateString()
                : ""
            }`}
          </strong>
          <span>{getStatusName(state)}</span>
        </p>
        <p className="text application-text">{`${
          (application.subNomination as any)?.nomination?.name
        }${
          (application.subNomination &&
            application.subNomination?.name !==
              (application.subNomination as any)?.nomination?.name) ??
          ""
            ? ": " + application.subNomination?.name
            : ""
        }`}</p>
        <p className="text application-text">{`${application.fullName}`}</p>
        {application.adminNote && application.state === 1 && (
          <div className="container-v">
            <p style={{ color: "black" }}>
              <strong>Комментарий координатора: </strong>
            </p>
            <p style={{ color: "black" }}>{application.adminNote}</p>
          </div>
        )}
      </div>
    );
  };

  const getLogInMessage = () => {
    return (
      <div className="container-v" style={{ alignItems: "flex-start" }}>
        <h2>Для просмотра ваших заявок необходимо авторизоваться на сайте!</h2>
      </div>
    );
  };

  return (
    <div className="contest longread">
      {applicationsData ? (
        <div className="content-section container-v center">
          <div className="content longread-content">
            {isAccessTokenValid() ? (
              <>
                <h1>МОИ ЗАЯВКИ</h1>
                <h2>Мои заявки</h2>
                {showAccepted &&
                  applicationsData.filter(
                    (a) =>
                      a.state === 4 &&
                      STAGE_SUBNOMINATIONS_IDS.indexOf(
                        a.subNominationId ?? ""
                      ) !== -1
                  ).length > 0 && (
                    <div className="container-v">
                      <p>Поздравляем, вы прошли в конкурс! </p>
                      <p>
                        Вам необходимо оплатить{" "}
                        <strong>организационный взнос </strong>по ссылке —{" "}
                        <a
                          href="https://qtickets.ru/event/137965?qpartner=bilet-uchastnika"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Билет участника
                        </a>
                        . Для парного или группового выступления взнос
                        оплачивается{" "}
                        <strong>за каждого участника отдельно </strong> (прим. 5
                        выступающих = 5 билетов). Пожалуйста, приобретайте
                        билеты заранее!
                      </p>
                      <p>
                        ВНИМАНИЕ! <br />
                        <strong>
                          Не передавайте ссылку третьим лицам.
                        </strong>{" "}
                        Вход на площадку фестиваля осуществляется по документам,
                        удостоверяющим личность. Любой гость, предъявивший билет
                        участника, но отсутствующий в списках,{" "}
                        <strong>не будет допущен</strong> на фестиваль.
                      </p>
                      <p>
                        <strong>Помощники</strong>, указанные в заявке, проходят
                        за полную стоимость входного билета и{" "}
                        <strong>должны приобрести билет гостя</strong> по ссылке
                        —{" "}
                        <a
                          href="https://nyafest.qtickets.ru/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Билет гостя
                        </a>
                      </p>
                    </div>
                  )}
                <div
                  className="container-v"
                  style={{ width: "100%", gap: "15px" }}
                >
                  {applicationsData.map((a) => {
                    return getApplicationCard(a);
                  })}
                </div>
              </>
            ) : (
              getLogInMessage()
            )}
          </div>
        </div>
      ) : (
        <div style={{ height: "100vh" }}>
          <div className="loading-overlay">
            <div className="spinner"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MyApplications;
