import React, { useEffect, useState } from "react";
import { getBlocks, setLikeScheduleItem } from "../services/scheduleService";
import { Block, ScheduleItem } from "../admin/types";
import "./SchedulePage.css";
import ImagePreviewWithPopUp from "../components/ImagePreviewWithPopUp";
import { FILES_URL } from "../config";
import { getCharPics } from "../services/applicationsService";

function ScheduleWithPictures() {
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [charPics, setCharPics] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<
    "schedule" | "map" | "info" | "contacts"
  >("schedule");

  const getAllBlocks = async () => {
    setIsLoading(true);
    const blocksData = await getBlocks();
    let time = new Date(2023, 1, 1, 12, 0, 0, 0).getTime();
    const orderedBlocks = blocksData
      .sort((a, b) => a.order - b.order)
      .map((b) => {
        const startTime = new Date(time);
        let newBlock = {
          ...b,
          duration: b.durationInSeconds / 60,
          startTime: `${startTime.getHours()}:${
            startTime.getMinutes() < 10
              ? "0" + startTime.getMinutes()
              : startTime.getMinutes()
          }`,
        };
        time += b.durationInSeconds * 1000;
        return newBlock;
      });
    setBlocks(orderedBlocks);
    setIsLoading(false);
  };

  const getCharacterPics = async () => {
    const charPics = await getCharPics();
    setCharPics(charPics);
  };

  const getHeader = (title: string, color: "blue" | "gray", time?: string) => {
    return (
      <div className={`container schedule-header schedule-header-${color}`}>
        <div className="schedule-header-title">{title}</div>
        {time && <div className="schedule-header-title">{time}</div>}
      </div>
    );
  };

  const getScheduleItem = (item: ScheduleItem, index: number) => {
    const charpic = charPics.find((p) => p.id === item.applicationId);
    return (
      <div key={item.scheduleItemId} className="container schedule-item">
        <div className="container-v" style={{ gap: "10px" }}>
          <div className="schedule-item-title">
            {index}. {item.name.split(" — ")[1]}
          </div>
          <div className="schedule-item-subtitle">
            {item.name.split(" — ")[0]}
          </div>
        </div>
        {charpic && (
          <ImagePreviewWithPopUp url={`${FILES_URL}/${charpic.char_pic}`} />
        )}
      </div>
    );
  };

  const getSchedule = () => {
    let blockIndex = 0;
    let scheduleItemIndex = 0;
    return (
      <div className="container-v">
        {blocks.map((b) => {
          return (
            <div className="container-v" key={b.blockId}>
              {getHeader(b.name, "gray", b.startTime)}
              {(b.name === "Открытие" || b.name === "Перерыв") &&
                getHeader(`${(blockIndex = blockIndex + 1)} Блок`, "blue")}
              {b.scheduleItems?.length > 0 &&
                b.scheduleItems.map((i) => {
                  scheduleItemIndex = scheduleItemIndex + 1;
                  return getScheduleItem(i, scheduleItemIndex);
                })}
            </div>
          );
        })}
      </div>
    );
  };

  const getScheduleMenu = (
    page: "schedule" | "map" | "info" | "contacts",
    iconUrl: string
  ) => {
    return (
      <div
        className={`container center schedule-header-${
          currentPage === page ? "blue" : "gray"
        }`}
        style={{ flex: 1, padding: "9px", cursor: "pointer" }}
        onClick={() => {
          setCurrentPage(page);
        }}
        key={page}
      >
        <img
          className="schedule-like"
          src={currentPage === page ? `white-${iconUrl}` : iconUrl}
          alt={page}
        />
      </div>
    );
  };

  const getMap = () => {
    return (
      <div className="container-v">
        <img src="fest-map-min.jpg" alt="NYAF" />
      </div>
    );
  };

  const getInfo = () => {
    return (
      <div className="container-v" style={{ color: "white" }}>
        <div
          className="schedule-item-title container-v"
          style={{ fontSize: "20px", margin: "10px" }}
        >
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{ marginBottom: "0px", width: "90px", textAlign: "end" }}
            >
              9:00
            </div>
            <div style={{ flex: "1", color: "white" }}>
              Вход для участников и помощников
            </div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{ marginBottom: "0px", width: "90px", textAlign: "end" }}
            >
              11:00
            </div>
            <div style={{ flex: "1", color: "white" }}>Вход для гостей</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{ marginBottom: "0px", width: "90px", textAlign: "end" }}
            >
              12:00
            </div>
            <div style={{ flex: "1", color: "white" }}>
              Начало сценической программы
            </div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{ marginBottom: "0px", width: "90px", textAlign: "end" }}
            >
              20:00
            </div>
            <div style={{ flex: "1", color: "white" }}>
              Окончание сценической программы
            </div>
          </div>
        </div>
        <div
          className="schedule-item-title container-v"
          style={{ fontSize: "20px", margin: "5px" }}
        >
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              1 этаж
            </div>
            <div style={{ flex: "1", color: "white" }}>Ярмарка</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              1 этаж
            </div>
            <div style={{ flex: "1", color: "white" }}>Фудкорт</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              1 этаж
            </div>
            <div style={{ flex: "1", color: "white" }}>
              JustDance и Файтинги
            </div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              2 этаж
            </div>
            <div style={{ flex: "1", color: "white" }}>Ярмарка</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              2 этаж
            </div>
            <div style={{ flex: "1", color: "white" }}>Аллея Авторов</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              2 этаж
            </div>
            <div style={{ flex: "1", color: "white" }}>Приглашенные гости</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              2 этаж
            </div>
            <div style={{ flex: "1", color: "white" }}>Фотозона</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              2 этаж
            </div>
            <div style={{ flex: "1", color: "white" }}>Стенды</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              2 этаж
            </div>
            <div style={{ flex: "1", color: "white" }}>Игротека</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              2 этаж
            </div>
            <div style={{ flex: "1", color: "white" }}>
              Стенд фестиваля и лотерея
            </div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              2 этаж
            </div>
            <div style={{ flex: "1", color: "white" }}>Лекторий</div>
          </div>
        </div>
        <div style={{ position: "relative", display: "inline-block" }}>
          <img
            className="yellow-ribbon"
            src="yellow-ribbon.png"
            alt="yellow-header"
          />
          <div className="ribbon-text">ЛЕКТОРИЙ</div>
        </div>
        <div
          className="schedule-item-title container-v"
          style={{ fontSize: "20px", margin: "10px", gap: "15px" }}
        >
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">12:30-13:00</div>
            <div className="info-text">
              <b>ARTWALL</b>: Эксклюзивная премьера первой серии анимационного
              сериала «Мәктәп ТВ»
            </div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">13:00-13:45</div>
            <div className="info-text">
              <b>ARTWALL</b>: Мастер-класс «Создание анимации бега для сериала
              «Мәктәп ТВ»
            </div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">14:00-14:45</div>
            <div className="info-text">
              <b>VALERIE</b>: «Развитие соцсетей косплееру. Построение
              комьюнити»
            </div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">15:00-16:00</div>
            <div className="info-text">
              <b>Никита Моисеев</b>: выступление и общение с фанатами
            </div>
          </div>
        </div>
        <div style={{ position: "relative", display: "inline-block" }}>
          <img
            className="yellow-ribbon"
            src="yellow-ribbon.png"
            alt="yellow-header"
          />
          <div className="ribbon-text">ИГРОТЕКА</div>
        </div>
        <div
          className="schedule-item-title container-v"
          style={{ fontSize: "20px", margin: "10px", gap: "15px" }}
        >
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-text">
              Партии по D&D и Shadowrun от клуба
              <span className="info-header">
                <b> «Убежище 177»</b>
              </span>
            </div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-text">
              <span className="info-header">
                <b>Мартенхо</b>
              </span>
              : клуб риичи-маджонга в Уфе
            </div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-text">
              Игротека настольных игр от{" "}
              <span className="info-header">
                <b>HOBBY GAMES</b>
              </span>
            </div>
          </div>
        </div>
        <div style={{ position: "relative", display: "inline-block" }}>
          <img
            className="yellow-ribbon"
            src="yellow-ribbon.png"
            alt="yellow-header"
          />
          <div className="ribbon-text">ФОЙЕ 2 ЭТАЖА</div>
        </div>
        <div
          className="schedule-item-title container-v"
          style={{ fontSize: "20px", margin: "10px" }}
        >
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">16:15-16:45</div>
            <div className="info-text">
              Автограф-сессия с <b>Дарьей Павлович</b>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getContacts = () => {
    return (
      <div className="container-v" style={{ color: "white" }}>
        <div style={{ position: "relative", display: "inline-block" }}>
          <img
            className="yellow-ribbon"
            src="yellow-ribbon.png"
            alt="yellow-header"
          />
          <div className="ribbon-text">МЕДИКИ</div>
        </div>
        <div
          className="schedule-item-title container-v"
          style={{ fontSize: "20px", margin: "10px", gap: "15px" }}
        >
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">ВЕРБА</div>
            <div className="info-text">+79196081969</div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">ЛЕРА</div>
            <div className="info-text">+79870298724</div>
          </div>
        </div>
        <div style={{ position: "relative", display: "inline-block" }}>
          <img
            className="yellow-ribbon"
            src="yellow-ribbon.png"
            alt="yellow-header"
          />
          <div className="ribbon-text">ОРГАНИЗАТОРЫ</div>
        </div>
        <div
          className="schedule-item-title container-v"
          style={{ fontSize: "20px", margin: "10px", gap: "15px" }}
        >
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">КАИН (Алина), главорг</div>
            <div className="info-text">+79033101989</div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">МАКО (Варвара), куратор сцены</div>
            <div className="info-text">+79374799509</div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">КЕНТЕЙ (Инна), куратор фойе</div>
            <div className="info-text">+79173715808</div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">ЛАЙТО (Наташа), куратор стенов</div>
            <div className="info-text">+79603953602</div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">ТИНА, куратор волонтеров</div>
            <div className="info-text">+79177449644</div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">
              КОРИЦА (Гульнара), помощник куратора волонтеров
            </div>
            <div className="info-text">+79178081576</div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">МУНПЕРС, маскот</div>
            <div className="info-text">+79603868677</div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">
              КОКО ФЕРРАРИ (Никита), проектор/звук
            </div>
            <div className="info-text">+79174187009</div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">ЕГОР, куратор жюри косплея</div>
            <div className="info-text">+79500473751</div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">ВАЛЕРИЯ, куратор жюри кд/вокала</div>
            <div className="info-text">+79196024094</div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">JUNKA (Алёна), вход в ДК</div>
            <div className="info-text">+79874966862</div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">ВЛАД, лекторий</div>
            <div className="info-text">+79273130088</div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">БИЛЛ (Анна), ведущий 1</div>
            <div className="info-text">+79254083202</div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">АБЕЛЬ (Настя), ведущий 2</div>
            <div className="info-text">+79053538798</div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getAllBlocks();
    getCharacterPics();
  }, []);

  return (
    <div className="container-v" style={{ width: "100%", marginTop: "60px" }}>
      {isLoading && (
        <div className="loading-overlay" style={{ position: "absolute" }}>
          <div className="spinner"></div>
        </div>
      )}
      <div
        className="fixed-header"
        style={{
          position: "fixed",
          top: "62px",
          left: "0",
          width: "100%",
          zIndex: "500",
          backgroundColor: "black", // or any color that suits your app
        }}
      >
        <div className="container">
          {getScheduleMenu("schedule", "schedule.svg")}
          {getScheduleMenu("map", "fest-map.svg")}
          {getScheduleMenu("info", "info-icon.svg")}
          {getScheduleMenu("contacts", "phone-call.svg")}
        </div>
      </div>
      <div style={{ height: "55px" }}></div>
      {currentPage === "schedule" && getSchedule()}
      {currentPage === "map" && getMap()}
      {currentPage === "info" && getInfo()}
      {currentPage === "contacts" && getContacts()}
    </div>
  );
}

export default ScheduleWithPictures;
