import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MyApplication } from "../types";
import { getApplications } from "../../services/applicationsService";
import "./Applicants.css";

type AuthorApplication = {
  nickname: string;
  FIO: string;
  city?: string;
  mobile?: string;
  email: string;
  socmedia?: string;
  extra_info?: string;
  portfolio?: string;
  merch_map?: string;
};

function AuthorsApplications() {
  const [allApplications, setAllApplications] = useState<MyApplication[]>([]);
  const [authorsApplications, setAuthorsApplications] = useState<
    AuthorApplication[]
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getAllApplications = async () => {
    setIsLoading(true);
    const applicationsData = await getApplications();
    setAllApplications(applicationsData);

    const authorsApplicationsTemp = applicationsData
      .filter(
        (a) => a.subNominationId === "24c2d5e2-de16-4226-b701-42d53c17b821"
      )
      .map((a) => {
        return {
          nickname: a.applicationData?.find((d) => d.field.code === "nickname")
            ?.value,
          city: a.applicationData?.find((d) => d.field.code === "city")?.value,
          mobile: a.applicationData?.find((d) => d.field.code === "mobile")
            ?.value,
          email: a.applicationData?.find((d) => d.field.code === "email")
            ?.value,
          socmedia: a.applicationData?.find((d) => d.field.code === "socmedia")
            ?.value,
          FIO: a.applicationData?.find((d) => d.field.code === "FIO")?.value,
          extra_info: a.applicationData?.find(
            (d) => d.field.code === "extra_info"
          )?.value,
          portfolio: a.applicationData?.find(
            (d) => d.field.code === "portfolio"
          )?.value,
          merch_map: a.applicationData?.find(
            (d) => d.field.code === "merch_map"
          )?.value,
        } as AuthorApplication;
      })
      .sort((a, b) => a.nickname.localeCompare(b.nickname));

    setAuthorsApplications(authorsApplicationsTemp);
    const csvString = convertToCSV(authorsApplicationsTemp);
    console.log(csvString);
    setIsLoading(false);
  };

  const convertToCSV = (data: AuthorApplication[]): string => {
    if (data.length === 0) return "";

    // Extract headers from the keys of the first object
    const headers = Object.keys(data[0]) as (keyof AuthorApplication)[];

    // Create the CSV string
    const csvRows = [
      headers.join(","), // Header row
      ...data.map((row) =>
        headers
          .map((header) => {
            const value = row[header] ?? ""; // Handle optional fields
            return `"${value.toString().replace(/"/g, '""')}"`; // Escape double quotes
          })
          .join(",")
      ),
    ];

    return csvRows.join("\n");
  };

  useEffect(() => {
    getAllApplications();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ marginTop: "70px", display: "flex", height: "90%" }}>
      {isLoading && (
        <div className="loading-overlay" style={{ position: "absolute" }}>
          <div className="spinner"></div>
        </div>
      )}
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, height: "80%", overflow: "auto" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="right">Nickname</TableCell>
              <TableCell align="right">FIO</TableCell>
              <TableCell align="right">City</TableCell>
              <TableCell align="right">Mobile</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Socmedia</TableCell>
              <TableCell align="right">Portfolio</TableCell>
              <TableCell align="right">MerchMap</TableCell>
              <TableCell align="right">ExtraInfo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {authorsApplications.map((row) => (
              <TableRow
                key={row.nickname}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{row.nickname}</TableCell>
                <TableCell align="center">{row.FIO}</TableCell>
                <TableCell align="center">{row.city}</TableCell>
                <TableCell align="center">{row.mobile}</TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center">{row.socmedia}</TableCell>
                <TableCell align="center">{row.portfolio}</TableCell>
                <TableCell align="center">{row.merch_map}</TableCell>
                <TableCell align="center">{row.extra_info}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default AuthorsApplications;
