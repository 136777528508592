import React, { useEffect, useState } from "react";
import { NominationWithApplications } from "../admin/types";
import { getNominationsWithApplicationsPublic } from "../services/nominationsService";
import { getSetting } from "../services/settingsService";

function AllApplications({ type }: { type: "scene" | "hall" }) {
  const [applications, setApplications] =
    useState<NominationWithApplications[]>();

  const [showAccepted, setShowAccepted] = useState<boolean>(false);
  const [showAcceptedHall, setShowAcceptedHall] = useState<boolean>(false);

  const getConfigValue = async (key: string) => {
    const value = await getSetting(key);
    return value;
  };

  const getShowAccepted = async () => {
    const value = await getConfigValue("showAccepted");
    setShowAccepted(value as unknown as boolean);
  };
  const getShowAcceptedHall = async () => {
    const value = await getConfigValue("showAcceptedHall");
    setShowAcceptedHall(value as unknown as boolean);
  };

  const getAllApplications = async () => {
    const applicationsData = await getNominationsWithApplicationsPublic();
    setApplications(
      filterApplications(
        applicationsData.filter(
          (a) => a.name !== "Служебная" && a.name !== "Внеконкурс"
        )
      )
    );
  };

  const filterApplications = (
    applicationData: NominationWithApplications[]
  ) => {
    const hallNominations = ["Аллея Авторов", "Ярмарка", "Аккредитация"];
    if (type === "hall") {
      return applicationData.filter(
        (n) => hallNominations.indexOf(n.name) !== -1
      );
    }
    if (type === "scene") {
      return applicationData.filter(
        (n) => hallNominations.indexOf(n.name) === -1
      );
    }
  };

  useEffect(() => {
    getAllApplications();
    getShowAccepted();
    getShowAcceptedHall();
    // eslint-disable-next-line
  }, []);

  const getColor = (state: any) => {
    const appState =
      (type === "scene" && showAccepted) ||
      (type === "hall" && showAcceptedHall)
        ? state
        : state === 4 || state === 3
        ? 2
        : state;

    if (appState === 4) {
      return "#01e0c4";
    }

    if (appState === 3) {
      return "#a0a0a0";
    }
    return "#a0a0a0";
  };

  const getStatus = (state: any) => {
    const appState = showAccepted
      ? state
      : state === 4 || state === 3
      ? 2
      : state;

    return appState;
  };

  return (
    <div className="contest longread">
      {applications ? (
        <div className="content-section container-v center">
          <div className="content">
            <div className="longread-content container-v">
              <h1>Пришедшие заявки</h1>
              <div className="container-v">
                {applications.map((n) => (
                  <div key={n.nominationId} className="container-v">
                    <h2>{n.name}</h2>
                    {n.subNominations.map((s) => (
                      <div
                        key={s.subNominationId}
                        className="container-v"
                        style={{ marginLeft: "15px" }}
                      >
                        {n.subNominations.length > 1 &&
                          s.applications.length > 0 && <h4>{s.name}</h4>}
                        {s.applications.length > 0 && (
                          <div className="regular-text">
                            {s.applications.map((a) => (
                              <div
                                style={{
                                  color: `${
                                    n.name !== "Аллея Авторов" &&
                                    n.name !== "Ярмарка"
                                      ? getColor(a.status)
                                      : "#a0a0a0"
                                  }`,
                                }}
                                className={`${
                                  getStatus(a.status) === 4 ? "text" : ""
                                }`}
                                key={`application-${a.id}`}
                              >
                                {a.fullName}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ height: "100vh" }}>
          <div className="loading-overlay">
            <div className="spinner"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AllApplications;
