import React, { ReactChild, useEffect, useState } from "react";
import { Photocosplay, UserVote } from "../admin/types";
import "./VotePreviewWithPopUp.css";
import ReactStars from "react-stars";
import { setPhotocosplayRating } from "../services/applicationsService";
import { isAccessTokenValid } from "../utils/authUtils";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FILES_URL } from "../config";
import { VoteType } from "../pages/VotingPage";

const VotePreviewWithPopUp = ({
  type,
  userVoteData,
  ratingChanged,
}: {
  type: VoteType;
  userVoteData: UserVote;
  ratingChanged: (applicationId: string, rating: number) => void;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const changeRating = async (newRating: number) => {
    ratingChanged(userVoteData.applicationId, newRating);
  };
  const isDesktop = window.screen.width > 1450;
  const isMobile = window.screen.width < 750;
  const onOverlayClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    // Check if the clicked element is the overlay, not the modal content
    if (
      event.target instanceof HTMLDivElement &&
      event.target.id === "modal_overlay"
    ) {
      // Close the modal
      setIsModalOpen(false);
    }
  };

  const [photos, setPhotos] = useState<string[]>([]);
  useEffect(() => {
    const ph = [];
    if (
      userVoteData.fields.photocosplay_1 &&
      userVoteData.fields.photocosplay_1 !== ""
    ) {
      ph.push(userVoteData.fields.photocosplay_1);
    }
    if (
      userVoteData.fields.photocosplay_2 &&
      userVoteData.fields.photocosplay_2 !== ""
    ) {
      ph.push(userVoteData.fields.photocosplay_2);
    }
    if (
      userVoteData.fields.photocosplay_3 &&
      userVoteData.fields.photocosplay_3 !== ""
    ) {
      ph.push(userVoteData.fields.photocosplay_3);
    }
    setPhotos(ph);
  }, [userVoteData]);

  // const getYouTubeId = (url: string) => {
  //   const match = url.match(
  //     /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]+)|(?:https?:\/\/)?(?:www\.)?youtube\.com\/.*v=([a-zA-Z0-9_-]+)/
  //   );
  //   return match ? match[1] || match[2] : null;
  // };

  const videoThumbnail =
    type === "edit"
      ? `${FILES_URL}/${userVoteData.fields.nyaf_edit_preview}`
      : null;
  const videoEmbedUrl =
    type === "edit" ? userVoteData.fields.nyaf_edit_link : null;

  return (
    <div>
      <div onClick={() => setIsModalOpen(true)}>
        {type === "edit" && videoThumbnail ? (
          <img
            className="photocosplay-thumbnail"
            src={videoThumbnail}
            //alt={photocosplay.videoName || "Video Thumbnail"}
            alt="video"
          />
        ) : (
          <img
            className="photocosplay-thumbnail"
            src={`${FILES_URL}/${userVoteData.fields.photocosplay_1}`}
            alt={userVoteData.fields.nick_model}
          />
        )}
      </div>
      {isModalOpen && (
        <div
          id="modal_overlay"
          className="modal-overlay"
          onClick={(event) => onOverlayClick(event)}
        >
          <div id="modal" className="photocosplay-modal">
            <div className="container-v" style={{ height: "100%" }}>
              {type === "edit" && videoEmbedUrl ? (
                <iframe
                  style={{ maxWidth: "100%", height: "80%" }}
                  src={videoEmbedUrl}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title={userVoteData.applicationId}
                ></iframe>
              ) : (
                <Carousel showThumbs={false} swipeable={true}>
                  <img
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      width: "unset",
                    }}
                    src={`${FILES_URL}/${userVoteData.fields.char_pic}`}
                    alt={userVoteData.fields.char_name}
                  />
                  {
                    photos.map((p) => {
                      return (
                        <img
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            width: "unset",
                          }}
                          src={`${FILES_URL}/${p}`}
                          alt={userVoteData.fields.char_name}
                        />
                      );
                    }) as unknown as ReactChild
                  }
                </Carousel>
              )}
              <div
                className="container-v photocosplay-rating"
                style={{ overflowY: "auto" }}
              >
                {type === "photo" && (
                  <>
                    <div
                      className="regular-text photocosplay-text"
                      style={{ margin: "25px 0px 2px 0px" }}
                    >
                      <strong>Персонаж: </strong>
                      {userVoteData.fields.char_name}
                    </div>

                    <div
                      className="regular-text photocosplay-text"
                      style={{ margin: "2px 0px" }}
                    >
                      <strong>Фандом: </strong>
                      {userVoteData.fields.fandom}
                    </div>
                    <div
                      className="regular-text photocosplay-text"
                      style={{ margin: "2px 0px" }}
                    >
                      <strong>Косплеер: </strong>
                      {userVoteData.fields.nick_model}
                    </div>
                    <div
                      className="regular-text photocosplay-text"
                      style={{ margin: "2px 0px" }}
                    >
                      <strong>Фотограф: </strong>
                      {userVoteData.fields.nick_photographer}
                    </div>
                  </>
                )}
                {type === "pet" && (
                  <>
                    <div
                      className="regular-text photocosplay-text"
                      style={{ margin: "25px 0px 2px 0px" }}
                    >
                      <strong>Персонаж: </strong>
                      {userVoteData.fields.char_name}
                    </div>

                    <div
                      className="regular-text photocosplay-text"
                      style={{ margin: "2px 0px" }}
                    >
                      <strong>Фандом: </strong>
                      {userVoteData.fields.fandom}
                    </div>
                    <div
                      className="regular-text photocosplay-text"
                      style={{ margin: "2px 0px" }}
                    >
                      <strong>Кличка питомца: </strong>
                      {userVoteData.fields.pet_name}
                    </div>
                    <div
                      className="regular-text photocosplay-text"
                      style={{ margin: "2px 0px" }}
                    >
                      <strong>Возраст питомца: </strong>
                      {userVoteData.fields.pet_age}
                    </div>
                  </>
                )}
                {type === "edit" && (
                  <>
                    <div
                      className="regular-text photocosplay-text"
                      style={{ margin: "2px 0px" }}
                    >
                      <strong>Автор: </strong>
                      {userVoteData.fields.nickname}
                    </div>
                    <div
                      className="regular-text photocosplay-text"
                      style={{ margin: "2px 0px" }}
                    >
                      <strong>Название видео: </strong>
                      {userVoteData.fields.video_name}
                    </div>
                    <div
                      className="regular-text photocosplay-text"
                      style={{ margin: "2px 0px" }}
                    >
                      <strong>Фандом: </strong>
                      {userVoteData.fields.fandom}
                    </div>
                  </>
                )}
                {isAccessTokenValid() ? (
                  <>
                    <ReactStars
                      count={10}
                      onChange={changeRating}
                      size={isDesktop ? 50 : isMobile ? 25 : 30}
                      half={false}
                      value={userVoteData.rating}
                      color1={"#D9D9D9"}
                      color2={"#01BBF8"}
                    />
                    {!!userVoteData.rating && (
                      <div className="text photocosplay-text">
                        Ваша оценка: {userVoteData.rating}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="regular-text" style={{ color: "black" }}>
                    Для голосования необходимо авторизоваться на сайте!
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VotePreviewWithPopUp;
