import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MyApplication } from "../types";
import { getApplications } from "../../services/applicationsService";
import "./Applicants.css";

type FairApplication = {
  shop_name: string;
  FIO: string;
  city?: string;
  mobile?: string;
  email: string;
  socmedia?: string;
  extra_info?: string;
  shop_link: string;
  assistant?: string;
  shop_description?: string;
  tables?: string;
  equipment?: string;
  interaction?: string;
  merch?: string;
};

function FairApplications() {
  const [allApplications, setAllApplications] = useState<MyApplication[]>([]);
  const [fairApplications, setFairApplications] = useState<FairApplication[]>(
    []
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getAllApplications = async () => {
    setIsLoading(true);
    const applicationsData = await getApplications();
    setAllApplications(applicationsData);

    const fairApplicationsTemp = applicationsData
      .filter(
        (a) => a.subNominationId === "596e3689-9cf6-4600-89be-01259c1170b9"
      )
      .map((a) => {
        return {
          shop_name: a.applicationData?.find(
            (d) => d.field.code === "shop_name"
          )?.value,
          city: a.applicationData?.find((d) => d.field.code === "city")?.value,
          mobile: a.applicationData?.find((d) => d.field.code === "mobile")
            ?.value,
          email: a.applicationData?.find((d) => d.field.code === "email")
            ?.value,
          socmedia: a.applicationData?.find((d) => d.field.code === "socmedia")
            ?.value,
          FIO: a.applicationData?.find((d) => d.field.code === "FIO")?.value,
          extra_info: a.applicationData?.find(
            (d) => d.field.code === "extra_info"
          )?.value,
          shop_link: a.applicationData?.find(
            (d) => d.field.code === "shop_link"
          )?.value,
          assistant: a.applicationData?.find(
            (d) => d.field.code === "assistant"
          )?.value,
          shop_description: a.applicationData?.find(
            (d) => d.field.code === "shop_description"
          )?.value,
          tables: a.applicationData?.find((d) => d.field.code === "tables")
            ?.value,
          equipment: a.applicationData?.find(
            (d) => d.field.code === "equipment"
          )?.value,
          interaction: a.applicationData?.find(
            (d) => d.field.code === "interaction"
          )?.value,
          merch: a.applicationData?.find((d) => d.field.code === "merch")
            ?.value,
        } as FairApplication;
      })
      .sort((a, b) => a.shop_name.localeCompare(b.shop_name));

    setFairApplications(fairApplicationsTemp);
    const csvString = convertToCSV(fairApplicationsTemp);
    console.log(csvString);
    setIsLoading(false);
  };

  const convertToCSV = (data: FairApplication[]): string => {
    if (data.length === 0) return "";

    // Extract headers from the keys of the first object
    const headers = Object.keys(data[0]) as (keyof FairApplication)[];

    // Create the CSV string
    const csvRows = [
      headers.join(","), // Header row
      ...data.map((row) =>
        headers
          .map((header) => {
            const value = row[header] ?? ""; // Handle optional fields
            return `"${value.toString().replace(/"/g, '""')}"`; // Escape double quotes
          })
          .join(",")
      ),
    ];

    return csvRows.join("\n");
  };

  useEffect(() => {
    getAllApplications();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ marginTop: "70px", display: "flex", height: "90%" }}>
      {isLoading && (
        <div className="loading-overlay" style={{ position: "absolute" }}>
          <div className="spinner"></div>
        </div>
      )}
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, height: "80%", overflow: "auto" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">FIO</TableCell>
              <TableCell align="right">City</TableCell>
              <TableCell align="right">Mobile</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Socmedia</TableCell>
              <TableCell align="right">Assistent</TableCell>
              <TableCell align="right">Shop Link</TableCell>
              <TableCell align="right">Shop Description</TableCell>
              <TableCell align="right">Tables</TableCell>
              <TableCell align="right">Equipment</TableCell>
              <TableCell align="right">Interaction</TableCell>
              <TableCell align="right">Merch</TableCell>
              <TableCell align="right">Extra info</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fairApplications.map((row) => (
              <TableRow
                key={row.shop_name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{row.shop_name}</TableCell>
                <TableCell align="center">{row.FIO}</TableCell>
                <TableCell align="center">{row.city}</TableCell>
                <TableCell align="center">{row.mobile}</TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center">{row.socmedia}</TableCell>
                <TableCell align="center">{row.assistant}</TableCell>
                <TableCell align="center">{row.shop_link}</TableCell>
                <TableCell align="center">{row.shop_description}</TableCell>
                <TableCell align="center">{row.tables}</TableCell>
                <TableCell align="center">{row.equipment}</TableCell>
                <TableCell align="center">{row.interaction}</TableCell>
                <TableCell align="center">{row.merch}</TableCell>
                <TableCell align="center">{row.extra_info}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default FairApplications;
