import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { OnlineVoteAdmin } from "../types";
import { getVotesAdmin } from "../../services/applicationsService";
import { VoteType } from "../../pages/VotingPage";

function OnlineVoteAdminPage() {
  const [votingData, setVotingData] = useState<OnlineVoteAdmin[]>();
  const [code, setCode] = useState<VoteType>("photo");

  useEffect(() => {
    async function fetchPage() {
      try {
        const votingData = await getVotesAdmin(code);
        setVotingData(votingData.sort((a, b) => b.rating - a.rating));
      } catch (error) {
        // Handle error
        console.error("Error fetching pages:", error);
      }
    }

    fetchPage();
  }, [code]);

  return (
    <div
      style={{
        marginTop: "70px",
        display: "flex",
      }}
    >
      <Box sx={{ width: "700px", padding: "10px", overflowY: "auto" }}>
        <div className="container" style={{ gap: "15px" }}>
          <Button onClick={() => setCode("photo")}>ФОТОКОСПЛЕЙ</Button>
          <Button onClick={() => setCode("pet")}>ПЕТ-КОСПЛЕЙ</Button>
          <Button onClick={() => setCode("edit")}>КОНКУРС ЭДИТОВ</Button>
        </div>
        {code === "photo" && <div>ФОТОКОСПЛЕЙ</div>}
        {code === "pet" && <div>ПЕТ-КОСПЛЕЙ</div>}
        {code === "edit" && <div>КОНКУРС ЭДИТОВ</div>}
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650, height: "80%", overflow: "auto" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>ID заявки</TableCell>
                <TableCell align="left">Выступление</TableCell>
                <TableCell align="left">Рейтинг</TableCell>
                <TableCell align="left">Количество голосов</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {votingData?.map((row) => (
                <TableRow
                  key={row.applicationId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.applicationId}
                  </TableCell>
                  <TableCell align="left">{row.fullName}</TableCell>
                  <TableCell align="left" title={row.rating.toString()}>
                    {row.rating.toFixed(3)}
                  </TableCell>
                  <TableCell align="left">{row.voteCount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}

export default OnlineVoteAdminPage;
