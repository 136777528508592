import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React from "react";
import { Page } from "../types";
import { Outlet } from "react-router-dom";

const pages: Page[] = [
  {
    name: "Отбор заявок",
    link: "applications",
  },
  {
    name: "Настройки",
    link: "settings",
  },
  {
    name: "Онлайн-голосование",
    link: "photocosplay-admin",
  },
  {
    name: "Зрительское голосование",
    link: "uservote-admin",
  },
];

function Applications() {
  return (
    <div style={{ marginTop: "70px", display: "flex", height: "100%" }}>
      <Box sx={{ width: "200px" }}>
        <List>
          {pages.map((page) => (
            <ListItem key={page.link}>
              <ListItemButton href={`/admin/applications/${page.link}`}>
                <ListItemText primary={page.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <Divider orientation="vertical" />
      <Outlet />
    </div>
  );
}

export default Applications;
