// pageService.ts

import { BASE_URL } from "../config";
import { fetchWithAuth } from "../utils/authUtils";

// Helper function to handle fetch errors
const handleFetchError = (error: any) => {
  console.error("API Request Failed:", error);
};

export async function getNextQuestStep(step: string): Promise<string> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/quests/nyaf2024/progress`,
      "POST",
      { providedStep: step }
    );

    if (!response.ok) {
      //throw new Error(`GET Request Failed with status ${response.status}`);
      const text = await response.text();
      return text;
    }

    const data = await response.text();
    return data as string;
  } catch (error) {
    handleFetchError(error);
    return Promise.reject(error);
  }
}
