import React from "react";
import "./PartnersSection.css";

const PartnersSection = ({
  heightStyle = "content-section",
}: {
  heightStyle?: string;
}) => {
  return (
    <div className={`${heightStyle} white-section container-v center`}>
      <div className="content container-v center" id="partners-section">
        <div className="text partner-text" style={{ marginTop: "100px" }}>
          Наши спонсоры и партнёры
        </div>
        <div
          className="container center wrap"
          style={{
            gap: "45px",
          }}
        >
          {" "}
          <a href="https://vk.com/gensei_shop" target="_blank" rel="noreferrer">
            <img
              className="partner-logo"
              src="/partner-images/gensei.png"
              alt="Gensei"
            />
          </a>
          <a
            href="https://vk.com/rusanimefest"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="partner-logo"
              src="/partner-images/rusanimefest_v2.png"
              alt="RusAnimeFest"
            />
          </a>
          <a href="http://lifemart.ru" target="_blank" rel="noreferrer">
            <img
              className="partner-logo"
              src="/partner-images/jizn_mart.png"
              alt="lifemart.ru"
            />
          </a>
          {/* <a
            href="https://vk.com/radioactive_daddy"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="partner-logo"
              src="/partner-images/logo2_tr-min.png"
              alt="radioactive_daddy"
            />
          </a> */}
          <a
            href="https://vk.com/hobbygames_ufa"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="partner-logo"
              src="/partner-images/HG_color_2.png"
              alt="Hobby Games"
            />
          </a>
          <a href="https://vk.com/sumiko_tv" target="_blank" rel="noreferrer">
            <img
              className="partner-logo"
              src="/partner-images/SUMIKO.png"
              alt="Sumiko"
            />
          </a>
          <a href="https://vetna.info/" target="_blank" rel="noreferrer">
            <img
              className="partner-logo"
              src="/partner-images/vetna.png"
              alt="vetna"
            />
          </a>
          <a href="https://domfandom.ru/" target="_blank" rel="noreferrer">
            <img
              className="partner-logo"
              src="/partner-images/domfandom.png"
              alt="domfandom"
            />
          </a>
          {/* <a
            href="https://likee.video/@NYAF_UFA"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="partner-logo"
              src="/partner-images/Likee.png"
              alt="Likee"
            />
          </a> */}
          {/* <a href="https://vk.com/voice_haus" target="_blank" rel="noreferrer">
            <img
              className="partner-logo"
              src="/partner-images/VoiceHaus.png"
              alt="Voice Haus"
            />
          </a> */}
          {/* <a href="https://vk.com/teguri.wear" target="_blank" rel="noreferrer">
            <img
              className="partner-logo"
              src="/partner-images/teguri.png"
              alt="Teguri"
            />
          </a> */}
          <a
            href="https://vk.com/materialsforart"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="partner-logo"
              src="/partner-images/ics.png"
              alt="ICS"
            />
          </a>
          <a href="https://t.me/comicsufa" target="_blank" rel="noreferrer">
            <img
              className="partner-logo"
              src="/partner-images/mezjdustrok.png"
              alt="mezjdustrok"
            />
          </a>
          <a href="https://vk.com/drugoeid" target="_blank" rel="noreferrer">
            <img
              className="partner-logo"
              src="/partner-images/drugoe.png"
              alt="drugoe"
            />
          </a>
          <a href="https://vk.com/sushi102" target="_blank" rel="noreferrer">
            <img
              className="partner-logo"
              src="/partner-images/fudziyama.png"
              alt="fudziyama"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default PartnersSection;
