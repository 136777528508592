import React, { useEffect, useState } from "react";
import { getBlocks, setLikeScheduleItem } from "../services/scheduleService";
import { Block, ScheduleItem } from "../admin/types";
import { isAccessTokenValid } from "../utils/authUtils";
import "./SchedulePage.css";
import { TextField } from "@mui/material";
import { assignTicketNumber, getTicketNumber } from "../services/userService";

function SchedulePage() {
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<
    "schedule" | "map" | "info" | "likes"
  >("schedule");
  const [ticketNumber, setTicketNumber] = useState<number>(0);
  const [ticketNumberInput, setTicketNumberInput] = useState<string>("");
  const [ticketErrorText, setTicketErrorText] = useState<string>("");

  const getAllBlocks = async () => {
    setIsLoading(true);
    const blocksData = await getBlocks();
    let time = new Date(2023, 1, 1, 12, 0, 0, 0).getTime();
    const orderedBlocks = blocksData
      .sort((a, b) => a.order - b.order)
      .map((b) => {
        const startTime = new Date(time);
        let newBlock = {
          ...b,
          duration: b.durationInSeconds / 60,
          startTime: `${startTime.getHours()}:${
            startTime.getMinutes() < 10
              ? "0" + startTime.getMinutes()
              : startTime.getMinutes()
          }`,
        };
        time += b.durationInSeconds * 1000;
        return newBlock;
      });
    setBlocks(orderedBlocks);
    setIsLoading(false);
  };

  const getUserTicketNumber = async () => {
    const ticketNumber = await getTicketNumber();
    setTicketNumber(ticketNumber);
  };

  const setUserTicketNumber = async (ticketNumber: number) => {
    try {
      const response = await assignTicketNumber({ ticketNumber: ticketNumber });
      if (response === ticketNumber) {
        setTicketErrorText("");
        await getUserTicketNumber();
      } else {
        setTicketErrorText(response as string);
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const getHeader = (title: string, color: "blue" | "gray", time?: string) => {
    return (
      <div className={`container schedule-header schedule-header-${color}`}>
        <div className="schedule-header-title">{title}</div>
        {time && <div className="schedule-header-title">{time}</div>}
      </div>
    );
  };

  const getScheduleItem = (item: ScheduleItem) => {
    return (
      <div key={item.scheduleItemId} className="container schedule-item">
        <div className="container-v" style={{ gap: "10px" }}>
          <div className="schedule-item-title">{item.name.split(" — ")[1]}</div>
          <div className="schedule-item-subtitle">
            {item.name.split(" — ")[0]}
          </div>
        </div>
        {isAccessTokenValid() && ticketNumber !== 0 && (
          <div className="container center">
            {item.liked ? (
              <img
                className="schedule-like"
                src="pink-heart.svg"
                alt="Liked"
                onClick={() => likeScheduleItem(item)}
              />
            ) : (
              <img
                className="schedule-like"
                src="heart.svg"
                alt="Like"
                onClick={() => likeScheduleItem(item)}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  const likeScheduleItem = (item: ScheduleItem) => {
    setLikeScheduleItem(item.applicationId);
    const blocksUpdated = [...blocks];
    const itemUpdate = blocksUpdated
      .find((b) => b.scheduleItems.includes(item))
      ?.scheduleItems.find((i) => i === item);
    if (itemUpdate) {
      itemUpdate.liked = !item.liked;
    }
    setBlocks(blocksUpdated);
  };

  const getSchedule = () => {
    let blockIndex = 0;
    return (
      <div className="container-v">
        {!isAccessTokenValid() ? (
          <div
            className="schedule-item-title container center"
            style={{ margin: "15px", color: "white" }}
          >
            <img
              className="schedule-like"
              src="pink-heart.svg"
              alt="Like"
              style={{ marginRight: "10px" }}
            />
            Для участия в зрительском голосовании необходимо авторизоваться на
            сайте!
          </div>
        ) : ticketNumber !== 0 ? (
          <div className="container-v">
            <div
              className="schedule-item-title container center"
              style={{
                color: "white",
                margin: "5px 15px 0px 15px",
                gap: "10px",
              }}
            >
              Ваш номер билета:{" "}
              <span style={{ color: "#f15bb4" }}>{ticketNumber}</span>
            </div>
            <div
              className="schedule-item-title container center"
              style={{ margin: "15px", color: "white" }}
            >
              <img
                className="schedule-like"
                src="pink-heart.svg"
                alt="Like"
                style={{ marginRight: "10px" }}
              />
              Ставьте лайки понравившимся выступлениям! Участники с наибольшим
              количеством лайков получат призы зрительских симпатий!
            </div>
          </div>
        ) : (
          <div className="container-v" style={{ backgroundColor: "white" }}>
            <div
              className="schedule-item-title container center"
              style={{ margin: "15px 15px 5px 15px" }}
            >
              <img
                className="schedule-like"
                src="pink-heart.svg"
                alt="Like"
                style={{ marginRight: "10px" }}
              />
              Для участия в зрительском голосовании пожалуйста введите номер
              вашего билета! <br /> Номер билета можно найти в PDF файле с
              билетом в поле Серия / Номер, 8 цифр
            </div>
            <div
              className="schedule-item-title container center"
              style={{ margin: "15px", color: "white", alignItems: "center" }}
            >
              <TextField
                style={{ flex: 1, marginRight: "10px" }}
                onChange={(event) => {
                  setTicketNumberInput(event.target.value);
                }}
              />
              <button
                onClick={() => {
                  setIsLoading(true);
                  setUserTicketNumber(parseInt(ticketNumberInput));
                }}
                className="button"
                style={{ marginTop: "0px" }}
              >
                ОК
              </button>
            </div>
            {ticketErrorText && (
              <div style={{ color: "red", margin: "0px 15px 15px" }}>
                {ticketErrorText === "Ticket already assigned to a user" &&
                  "Этот билет уже использован!"}
                {ticketErrorText === "Ticket not found" &&
                  "Билет с таким номером не найден!"}
              </div>
            )}
          </div>
        )}
        {blocks.map((b) => {
          return (
            <div className="container-v" key={b.blockId}>
              {getHeader(b.name, "gray", b.startTime)}
              {(b.name === "Открытие" || b.name === "Перерыв") &&
                getHeader(`${(blockIndex = blockIndex + 1)} Блок`, "blue")}
              {b.scheduleItems?.length > 0 &&
                b.scheduleItems.map((i) => {
                  return getScheduleItem(i);
                })}
            </div>
          );
        })}
      </div>
    );
  };

  const getLikes = () => {
    const likes: any[] = [];
    blocks.forEach((b) => {
      const likedItems = b.scheduleItems.filter((i) => i.liked);
      if (likedItems.length > 0) {
        const nomination = likes.find((l) => l.name === b.name);
        if (nomination === undefined) {
          likes.push({
            name: b.name,
            likes: likedItems,
          });
        } else {
          nomination.likes = [...nomination.likes, ...likedItems];
        }
      }
    });
    return (
      <div className="container-v" style={{ backgroundColor: "white" }}>
        {!isAccessTokenValid() ? (
          <div
            className="schedule-item-title container center"
            style={{ margin: "15px" }}
          >
            <img
              className="schedule-like"
              src="pink-heart.svg"
              alt="Like"
              style={{ marginRight: "10px" }}
            />
            Для участия в зрительском голосовании необходимо авторизоваться на
            сайте!
          </div>
        ) : (
          <div
            className="schedule-item-title container center"
            style={{ margin: "15px" }}
          >
            <img
              className="schedule-like"
              src="pink-heart.svg"
              alt="Like"
              style={{ marginRight: "10px" }}
            />
            Выступления, которые вам понравились
          </div>
        )}
        {likes.map((l) => {
          return (
            <div className="container-v">
              {getHeader(l.name, "gray")}
              {l.likes.map((li: any) => {
                return getScheduleItem(li);
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const getScheduleMenu = (
    page: "schedule" | "map" | "info" | "likes",
    iconUrl: string
  ) => {
    return (
      <div
        className={`container center schedule-header-${
          currentPage === page ? "blue" : "gray"
        }`}
        style={{ flex: 1, padding: "9px", cursor: "pointer" }}
        onClick={() => {
          setCurrentPage(page);
        }}
        key={page}
      >
        <img
          className="schedule-like"
          src={currentPage === page ? `white-${iconUrl}` : iconUrl}
          alt={page}
        />
      </div>
    );
  };

  const getMap = () => {
    return (
      <div className="container-v">
        <img src="fest-map-min.jpg" alt="NYAF" />
      </div>
    );
  };

  const getInfo = () => {
    return (
      <div className="container-v" style={{ color: "white" }}>
        <div
          className="schedule-item-title container-v center"
          style={{
            fontSize: "16px",
            margin: "10px 17px 5px 17px",
            textAlign: "center",
            color: "white",
          }}
        >
          Фестиваль пройдет по адресу ГКДЦ «Химик» , ул. Первомайская, 47
        </div>
        <div
          className="container"
          style={{ justifyContent: "space-around", alignItems: "center" }}
        >
          <a
            target="_blank"
            rel="noreferrer"
            href="https://yandex.ru/maps/172/ufa/house/pervomayskaya_ulitsa_47/YU8Ydg5gTUcHQFtufXRwdHtiZA==/?ll=56.090420%2C54.815710&z=16.98"
          >
            <img
              className="map-link"
              style={{ maxWidth: "30px" }}
              src="yandex-maps-small.png"
              alt="Yandex Maps"
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://2gis.ru/ufa/firm/2393065583058352?m=56.090426%2C54.815605%2F16"
          >
            <img
              className="map-link"
              style={{ maxWidth: "35px" }}
              src="2gis-maps-small.png"
              alt="2GIS Maps"
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.google.com/maps/place/%22%D0%93%D0%BE%D1%80%D0%BE%D0%B4%D1%81%D0%BA%D0%BE%D0%B9+%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%BD%D0%BE-%D0%B4%D0%BE%D1%81%D1%83%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9+%D1%86%D0%B5%D0%BD%D1%82%D1%80%22/@54.813533,56.0851833,17z/data=!4m15!1m8!3m7!1s0x43d94802dce91629:0xe9267b6da897f26b!2z0J_QtdGA0LLQvtC80LDQudGB0LrQsNGPINGD0LsuLCA0Nywg0KPRhNCwLCDQoNC10YHQvy4g0JHQsNGI0LrQvtGA0YLQvtGB0YLQsNC9LCDQoNC-0YHRgdC40Y8sIDQ1MDExMg!3b1!8m2!3d54.8135299!4d56.0877582!16s%2Fg%2F11g8crzl85!3m5!1s0x43d949343f009fc9:0xc4c264a4621ae98d!8m2!3d54.8156028!4d56.0903521!16s%2Fg%2F11g043h6hw?entry=ttu&g_ep=EgoyMDI0MTAwOS4wIKXMDSoASAFQAw%3D%3D"
          >
            <img
              className="map-link"
              style={{ maxWidth: "30px" }}
              src="google-maps-small.png"
              alt="Google Maps"
            />
          </a>
        </div>
        <div
          className="schedule-item-title container-v"
          style={{ fontSize: "20px", margin: "17px" }}
        >
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{ marginBottom: "0px", width: "90px", textAlign: "end" }}
            >
              9:00
            </div>
            <div style={{ flex: "1", color: "white" }}>
              Вход для участников и помощников
            </div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{ marginBottom: "0px", width: "90px", textAlign: "end" }}
            >
              11:00
            </div>
            <div style={{ flex: "1", color: "white" }}>Вход для гостей</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{ marginBottom: "0px", width: "90px", textAlign: "end" }}
            >
              12:00
            </div>
            <div style={{ flex: "1", color: "white" }}>
              Начало сценической программы
            </div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{ marginBottom: "0px", width: "90px", textAlign: "end" }}
            >
              20:00
            </div>
            <div style={{ flex: "1", color: "white" }}>
              Окончание сценической программы
            </div>
          </div>
        </div>
        <a
          className="info-link"
          href="https://vk.com/afterparty_nyaf"
          target="_blank"
          rel="noreferrer"
        >
          AFTERPARTY, 18+
        </a>
        <div
          className="schedule-item-title container-v center"
          style={{
            fontSize: "16px",
            margin: "5px 17px 20px 17px",
            textAlign: "center",
            color: "white",
          }}
        >
          Афтерпати фестиваля пройдет в воскресенье, 15 декабря, по адресу
          VGOSTIBAR, Коммунистическая, 50
        </div>
        <div style={{ position: "relative", display: "inline-block" }}>
          <img
            className="yellow-ribbon"
            src="yellow-ribbon.png"
            alt="yellow-header"
          />
          <div className="ribbon-text">НЕ ПРОПУСТИТЕ</div>
        </div>
        <div
          className="schedule-item-title container-v"
          style={{ fontSize: "20px", margin: "5px" }}
        >
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              1 этаж
            </div>
            <div style={{ flex: "1", color: "white" }}>Ярмарка</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              1 этаж
            </div>
            <div style={{ flex: "1", color: "white" }}>Фудкорт</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              1 этаж
            </div>
            <div style={{ flex: "1", color: "white" }}>
              JustDance и Файтинги
            </div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              2 этаж
            </div>
            <div style={{ flex: "1", color: "white" }}>Ярмарка</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              2 этаж
            </div>
            <div style={{ flex: "1", color: "white" }}>Аллея Авторов</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              2 этаж
            </div>
            <div style={{ flex: "1", color: "white" }}>Приглашенные гости</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              2 этаж
            </div>
            <div style={{ flex: "1", color: "white" }}>Фотозона</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              2 этаж
            </div>
            <div style={{ flex: "1", color: "white" }}>Стенды</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              2 этаж
            </div>
            <div style={{ flex: "1", color: "white" }}>Игротека</div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              2 этаж
            </div>
            <div style={{ flex: "1", color: "white" }}>
              Стенд фестиваля и лотерея
            </div>
          </div>
          <div
            className="container"
            style={{ gap: "15px", alignItems: "center" }}
          >
            <div
              className="header"
              style={{
                marginBottom: "0px",
                width: "90px",
                textAlign: "end",
                fontSize: "23px",
              }}
            >
              2 этаж
            </div>
            <div style={{ flex: "1", color: "white" }}>Лекторий</div>
          </div>
        </div>
        <div style={{ position: "relative", display: "inline-block" }}>
          <img
            className="yellow-ribbon"
            src="yellow-ribbon.png"
            alt="yellow-header"
          />
          <div className="ribbon-text">ЛЕКТОРИЙ</div>
        </div>
        <div
          className="schedule-item-title container-v"
          style={{ fontSize: "20px", margin: "10px", gap: "15px" }}
        >
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">12:30-13:00</div>
            <div className="info-text">
              <b>ARTWALL</b>: Эксклюзивная премьера первой серии анимационного
              сериала «Мәктәп ТВ»
            </div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">13:00-13:45</div>
            <div className="info-text">
              <b>ARTWALL</b>: Мастер-класс «Создание анимации бега для сериала
              «Мәктәп ТВ»
            </div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">14:00-14:45</div>
            <div className="info-text">
              <b>VALERIE</b>: «Развитие соцсетей косплееру. Построение
              комьюнити»
            </div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">15:00-16:00</div>
            <div className="info-text">
              <b>Никита Моисеев</b>: выступление и общение с фанатами
            </div>
          </div>
        </div>
        <div style={{ position: "relative", display: "inline-block" }}>
          <img
            className="yellow-ribbon"
            src="yellow-ribbon.png"
            alt="yellow-header"
          />
          <div className="ribbon-text">ИГРОТЕКА</div>
        </div>
        <div
          className="schedule-item-title container-v"
          style={{ fontSize: "20px", margin: "10px", gap: "15px" }}
        >
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-text">
              Партии по D&D и Shadowrun от клуба
              <span className="info-header">
                <b> «Убежище 177»</b>
              </span>
            </div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-text">
              <span className="info-header">
                <b>Мартенхо</b>
              </span>
              : клуб риичи-маджонга в Уфе
            </div>
          </div>
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-text">
              Игротека настольных игр от{" "}
              <span className="info-header">
                <b>HOBBY GAMES</b>
              </span>
            </div>
          </div>
        </div>
        <div style={{ position: "relative", display: "inline-block" }}>
          <img
            className="yellow-ribbon"
            src="yellow-ribbon.png"
            alt="yellow-header"
          />
          <div className="ribbon-text">ФОЙЕ 2 ЭТАЖА</div>
        </div>
        <div
          className="schedule-item-title container-v"
          style={{ fontSize: "20px", margin: "10px" }}
        >
          <div
            className="container-v"
            style={{ gap: "5px", alignItems: "start" }}
          >
            <div className="info-header">16:15-16:45</div>
            <div className="info-text">
              Автограф-сессия с <b>Дарьей Павлович</b>
            </div>
          </div>
        </div>
        <div className="container-v center">
          <div style={{ position: "relative", display: "inline-block" }}>
            <img
              className="yellow-ribbon"
              src="yellow-ribbon.png"
              alt="yellow-header"
            />
            <div className="ribbon-text" style={{ fontSize: "18px" }}>
              СПОНСОРЫ И ПАРТНЕРЫ
            </div>
          </div>
          <div
            className="container center wrap mobile-partners"
            style={{
              gap: "10px",
            }}
          >
            {" "}
            <a
              href="https://vk.com/gensei_shop"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="partner-logo"
                src="/partner-images/gensei.png"
                alt="Gensei"
              />
            </a>
            <a
              href="https://vk.com/rusanimefest"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="partner-logo"
                src="/partner-images/rusanimefest_v2.png"
                alt="RusAnimeFest"
              />
            </a>
            <a href="http://lifemart.ru" target="_blank" rel="noreferrer">
              <img
                className="partner-logo"
                src="/partner-images/jizn_mart.png"
                alt="lifemart.ru"
              />
            </a>
            <a
              href="https://vk.com/hobbygames_ufa"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="partner-logo"
                src="/partner-images/HG_color_2.png"
                alt="Hobby Games"
              />
            </a>
            <a href="https://vk.com/sumiko_tv" target="_blank" rel="noreferrer">
              <img
                className="partner-logo"
                src="/partner-images/SUMIKO.png"
                alt="Sumiko"
              />
            </a>
            <a href="https://vetna.info/" target="_blank" rel="noreferrer">
              <img
                className="partner-logo"
                src="/partner-images/vetna.png"
                alt="vetna"
              />
            </a>
            <a href="https://domfandom.ru/" target="_blank" rel="noreferrer">
              <img
                className="partner-logo"
                src="/partner-images/domfandom.png"
                alt="domfandom"
              />
            </a>
            <a
              href="https://vk.com/materialsforart"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="partner-logo"
                src="/partner-images/ics.png"
                alt="ICS"
              />
            </a>
            <a href="https://t.me/comicsufa" target="_blank" rel="noreferrer">
              <img
                className="partner-logo"
                src="/partner-images/mezjdustrok.png"
                alt="mezjdustrok"
              />
            </a>
            <a href="https://vk.com/drugoeid" target="_blank" rel="noreferrer">
              <img
                className="partner-logo"
                src="/partner-images/drugoe.png"
                alt="drugoe"
              />
            </a>
            <a href="https://vk.com/sushi102" target="_blank" rel="noreferrer">
              <img
                className="partner-logo"
                src="/partner-images/fudziyama.png"
                alt="fudziyama"
              />
            </a>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getUserTicketNumber();
    getAllBlocks();
  }, []);

  return (
    <div className="container-v" style={{ width: "100%", marginTop: "60px" }}>
      {isLoading && (
        <div className="loading-overlay" style={{ position: "absolute" }}>
          <div className="spinner"></div>
        </div>
      )}
      {/* Fixed header section */}
      <div
        className="fixed-header"
        style={{
          position: "fixed",
          top: "62px",
          left: "0",
          width: "100%",
          zIndex: "500",
          backgroundColor: "black", // or any color that suits your app
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            margin: "8px 10px 5px 10px",
            alignItems: "center",
          }}
        >
          <h5 style={{ color: "white", margin: "0px" }}>
            КВЕСТ NYAF 2024: КИБЕРПАНК{" "}
          </h5>
          <button
            className="button"
            style={{
              marginTop: "0px",
              fontSize: "16px",
              fontWeight: "700",
              padding: "10px",
              height: "40px",
            }}
            onClick={() => {
              window.location.href = "/quest/start";
            }}
          >
            НАЧАТЬ
          </button>
        </div>
        <div className="container" id="menu">
          {getScheduleMenu("schedule", "schedule.svg")}
          {getScheduleMenu("likes", "heart.svg")}
          {getScheduleMenu("map", "fest-map.svg")}
          {getScheduleMenu("info", "info-icon.svg")}
        </div>
      </div>
      {/* Space filler to offset fixed header height */}
      <div style={{ height: "107px" }}></div>
      {/* Scrollable content */}
      {currentPage === "schedule" && getSchedule()}
      {currentPage === "likes" && getLikes()}
      {currentPage === "map" && getMap()}
      {currentPage === "info" && getInfo()}
    </div>
  );
}

export default SchedulePage;
