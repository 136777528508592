import {
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MyApplication, Nomination } from "../types";
import { getApplications } from "../../services/applicationsService";
import "./Applicants.css";
import {
  getNomination,
  getNominations,
} from "../../services/nominationsService";

function ExportAllApplications() {
  const [allApplications, setAllApplications] = useState<MyApplication[]>([]);
  const [cosplayApplications, setCosplayApplications] = useState<
    MyApplication[]
  >([]);
  const [nominations, setNominations] = useState<Nomination[]>([]);
  const [currentNomination, setCurrentNomination] = useState<Nomination>();
  const [showAccepted, setShowAccepted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getAllNominations = async () => {
    const nominationsData = await getNominations();
    setNominations(nominationsData);
  };

  const getCurrentNomination = async (nominationId?: string) => {
    if (nominationId) {
      const nomination = await getNomination(nominationId);
      setCurrentNomination(nomination);
    }
  };

  const getAllApplications = async () => {
    setIsLoading(true);
    const applicationsData = await getApplications();
    setAllApplications(
      applicationsData.sort((a, b) => a.fullName.localeCompare(b.fullName))
    );

    setIsLoading(false);
  };

  useEffect(() => {
    getAllNominations();
    getAllApplications();
  }, []);

  useEffect(() => {
    if (currentNomination?.subNominations) {
      let appsTemp = allApplications
        .filter(
          (a) =>
            currentNomination.subNominations?.find(
              (s) => s.subNominationId === a.subNominationId
            ) !== undefined
        )
        .sort((a, b) => a.subNominationId!.localeCompare(b.subNominationId!));
      appsTemp = showAccepted
        ? appsTemp.filter((a) => a.state === 4)
        : appsTemp;
      setCosplayApplications(appsTemp);
    }
  }, [allApplications, currentNomination, showAccepted]);

  return (
    <div style={{ marginTop: "70px", display: "flex", height: "90%" }}>
      {isLoading && (
        <div className="loading-overlay" style={{ position: "absolute" }}>
          <div className="spinner"></div>
        </div>
      )}
      <List style={{ overflowY: "auto", minWidth: "300px" }}>
        {nominations.map((nom) => (
          <ListItem key={nom.nominationId}>
            <ListItemButton
              onClick={() => {
                getCurrentNomination(nom.nominationId);
              }}
            >
              <ListItemText primary={nom.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <div className="container-v">
        <FormControlLabel
          control={
            // eslint-disable-next-line react/jsx-no-undef
            <Checkbox
              checked={showAccepted}
              id="required"
              onChange={(event) => {
                setShowAccepted(event.target.checked);
              }}
              inputProps={{
                "aria-label": "Показывать только прошедшие заявки",
              }}
            />
          }
          label="Показывать только прошедшие заявки"
        />
        {currentNomination && cosplayApplications?.length > 0 && (
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650, height: "80%", overflow: "auto" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Id заявки</TableCell>
                  <TableCell>Название</TableCell>
                  <TableCell>Подноминация</TableCell>
                  {cosplayApplications[0].applicationData
                    ?.sort((a, b) => a.fieldId.localeCompare(b.fieldId))
                    .map((field) => {
                      return (
                        <TableCell key={field.fieldId}>
                          {field.field.label}
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {cosplayApplications.map((row, index) => (
                  <TableRow
                    key={row.applicationId}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.applicationId}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.fullName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {
                        currentNomination.subNominations?.find(
                          (s) => s.subNominationId === row.subNominationId
                        )?.name
                      }
                    </TableCell>
                    {row.applicationData
                      ?.sort((a, b) => a.fieldId.localeCompare(b.fieldId))
                      .map((field) => {
                        return [
                          "audio_demo",
                          "audio",
                          "costume",
                          "char_pic",
                        ].indexOf(field.field.code) !== -1 &&
                          field.value !== "" ? (
                          <TableCell
                            key={field.fieldId}
                          >{`https://nyafest.ru/u/${field.value}`}</TableCell>
                        ) : (
                          <TableCell key={field.fieldId}>
                            {field.value}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
}

export default ExportAllApplications;
