import React, { useEffect, useState } from "react";
import "./Quest.css";
import { useLocation } from "react-router-dom";
import { isAccessTokenValid } from "../utils/authUtils";
import { IDetectedBarcode, Scanner } from "@yudiel/react-qr-scanner";
import { getNextQuestStep } from "../services/questService";

function Quest() {
  const { pathname } = useLocation();
  const id = pathname.replace("/quest/", "");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [stepError, setStepError] = useState<boolean>(false);
  const [tab, setTab] = useState<"quest" | "map">("quest");

  const [startTextVisible, setStartTextVisible] = useState<boolean>(true);
  const [startLogsVisible, setStartLogsVisible] = useState<boolean>(false);
  const [showOhNoButton, setShowOhNoButton] = useState(false);
  const [logs, setLogs] = useState<string[]>([]);

  const [currentLog, setCurrentLog] = useState("");
  const [qrScannerVisible, setQrScannerVisible] = useState<boolean>(false);
  const [scannerErrorVisible, setScannerErrorVisible] =
    useState<boolean>(false);

  const [showContent, setShowContent] = useState<boolean>(false);

  const logMessages = [
    "...загрузка",
    "...загрузка",
    "...обработка данных",
    "...выход в киберпространство",
    "...ошибка",
    "...ошибка",
  ];

  useEffect(() => {
    if (startLogsVisible) {
      let logIndex = 0;
      let charIndex = 0;

      const typeLetter = () => {
        if (logIndex < logMessages.length) {
          const currentMessage = logMessages[logIndex];

          // Add the current letter to `currentLog`
          setCurrentLog((prev) => prev + currentMessage[charIndex]);

          charIndex++;

          if (charIndex === currentMessage.length) {
            // Finish current log and prepare for next
            setLogs((prevLogs) => [...prevLogs, currentMessage]);
            setCurrentLog(""); // Reset current log for the next one
            charIndex = 0;
            logIndex++;
            setTimeout(typeLetter, 400); // Delay before starting next line
          } else {
            // Continue typing the current log
            setTimeout(typeLetter, 80); // Adjust typing speed here
          }
        } else {
          setShowOhNoButton(true); // Show the "Oh no!" button when typing finishes
        }
      };

      typeLetter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startLogsVisible]);

  useEffect(() => {
    getNextStep();
  });

  const getNextStep = async () => {
    const nextStep = await getNextQuestStep(id);
    if (nextStep === "Invalid step") {
      setStepError(true);
      setShowContent(true);
    } else if (nextStep !== id) {
      window.location.href = `/quest/${nextStep}`;
    } else {
      setShowContent(true);
    }
    setIsLoading(false);
  };

  const getStart = () => {
    return (
      <>
        {startTextVisible && (
          <>
            <div className="quest-intruction-text">
              <span className="quest-accent">Привет, путник из пустоши! </span>
              <br />
              <br /> Наши наноинженеры долго трудились над созданием фестиваля
              NYAF 2024. Все файлы загружены, кибер-импланты закуплены, наши
              герои уже на подходе! <br />
              <br />
              Пора погрузиться в увлекательный мир искусственного интеллекта!
            </div>
            <img
              style={{
                width: "180px",
                alignSelf: "center",
                margin: "20px 0px",
              }}
              src="/chibi-mascot.png"
              alt="Ayumi"
            />
            <button
              className="quest-button"
              onClick={() => {
                setStartTextVisible(false);
                setStartLogsVisible(true);
              }}
            >
              НАЧАТЬ ПРИКЛЮЧЕНИЕ
            </button>
          </>
        )}
        {startLogsVisible && (
          <>
            <div className="typewriter-text" style={{ width: "100%" }}>
              {logs.map((log, index) => (
                <div
                  key={index}
                  style={{ color: `${index > 3 ? "red" : "white"}` }}
                >
                  {log}
                </div>
              ))}
              {currentLog && <div>{currentLog}</div>}
            </div>
            {showOhNoButton && (
              <button
                style={{ marginTop: "auto" }}
                className="quest-button"
                onClick={() => {
                  window.location.href = "/quest/init";
                }}
              >
                О НЕТ!
              </button>
            )}
          </>
        )}
      </>
    );
  };

  const getInit = () => {
    return (
      <>
        <div className="quest-intruction-text">
          Что-то пошло не так! <br /> Эта кибер-реальность в опасности! <br />{" "}
          Данные утеряны… Что же делать? Нам нужен <b>Избранный</b>, способный
          исправить ошибки выхода в киберпространство! Может это ты? Ты
          выглядишь одиноким… я могу это исправить.{" "}
          <span className="quest-accent">Найди меня на фестивале</span>, получи
          инструкции по спасению этой реальности и ничего не бойся!
        </div>
        {!qrScannerVisible && (
          <img
            style={{
              width: "180px",
              alignSelf: "center",
              margin: "20px 0px",
            }}
            src="/chibi-mascot.png"
            alt="Ayumi"
          />
        )}
        {!qrScannerVisible && (
          <button
            className="quest-button qr-button"
            onClick={() => {
              setQrScannerVisible(true);
              setScannerErrorVisible(false);
            }}
          >
            <img src="/qr-scan.svg" alt="qrscan" />
          </button>
        )}
        {scannerErrorVisible && (
          <div style={{ color: "red" }}>Неверный код!</div>
        )}
        {qrScannerVisible && (
          <Scanner
            styles={{ container: { margin: "15px 0px" } }}
            onScan={handleQrScan}
          />
        )}
      </>
    );
  };

  const getStep = () => {
    return (
      <div className="container-v center" style={{ gap: "15px" }}>
        {getProgressBar(id)}
        {getInstruction(id)}
        {id === "hundred" ? (
          <button
            className="quest-button"
            style={{ alignSelf: "center", marginTop: "15px" }}
            onClick={() => {
              window.location.href = "/quest/last";
            }}
          >
            ПОЛУЧИТЬ ПРИЗ
          </button>
        ) : (
          !qrScannerVisible && (
            <button
              className="quest-button qr-button"
              onClick={() => {
                setQrScannerVisible(true);
                setScannerErrorVisible(false);
              }}
            >
              <img src="/qr-scan.svg" alt="qrscan" />
            </button>
          )
        )}
        {scannerErrorVisible && (
          <div style={{ color: "red" }}>Неверный код!</div>
        )}
        {qrScannerVisible && <Scanner onScan={handleQrScan} />}
      </div>
    );
  };

  const getLast = () => {
    return (
      <div className="container-v center">
        <div className="quest-intruction-text">
          <span className="quest-accent">ПОЗДРАВЛЯЕМ, ИЗБРАННЫЙ!</span> <br />
          <br />
          Ты уже прошел наш квест! Надеемся, что он тебе понравился ^^ <br />
          <br />
          Ещё больше приключений ждёт тебя на площадке нашего фестиваля - на
          ярмарке и в зрительном зале!
          <br />
          <br />
          Желаем веселого фестиваля!
        </div>
        <button className="quest-inactive-button" style={{ marginTop: "15px" }}>
          ПРИЗ ПОЛУЧЕН
        </button>
      </div>
    );
  };
  const getError = () => {
    return (
      <div className="container-v center">
        <div className="quest-intruction-text" style={{ textAlign: "center" }}>
          <span className="quest-accent">ПРОИЗОШЛА ОШИБКА!</span> <br />
          <br />
          Ты пропустил шаг!
        </div>
        <button
          className="quest-button"
          style={{ marginTop: "15px" }}
          onClick={() => {
            window.location.href = "/quest/start";
          }}
        >
          ВЕРНУТЬСЯ К ТЕКУЩЕЙ ПОДСКАЗКЕ
        </button>
      </div>
    );
  };

  const handleQrScan = (result: IDetectedBarcode[]) => {
    const value = result[0].rawValue;
    const idFromValue = value.replace("https://nyafest.ru/quest/", "");
    if (scannedIdCorrect(id, idFromValue)) {
      window.location.pathname = `/quest/${idFromValue}`;
    } else {
      setQrScannerVisible(false);
      setScannerErrorVisible(true);
    }
  };

  const scannedIdCorrect = (lastUserStep: string, scannedResult: string) => {
    switch (lastUserStep) {
      case "init":
        if (scannedResult === "zero") {
          return true;
        }
        return false;
      case "zero":
        if (scannedResult === "twenty") {
          return true;
        }
        return false;
      case "twenty":
        if (scannedResult === "forty") {
          return true;
        }
        return false;
      case "forty":
        if (scannedResult === "sixty") {
          return true;
        }
        return false;
      case "sixty":
        if (scannedResult === "eighty") {
          return true;
        }
        return false;
      case "eighty":
        if (scannedResult === "ninetynine") {
          return true;
        }
        return false;
      case "ninetynine":
        if (scannedResult === "hundred") {
          return true;
        }
        return false;

      default:
        break;
    }
  };
  const getProgressBar = (step: string) => {
    let progressBarSrc = "/progressbar/0.svg";
    let percentage = 0;
    switch (step) {
      case "zero":
        progressBarSrc = "/progressbar/0.svg";
        percentage = 0;
        break;
      case "twenty":
        progressBarSrc = "/progressbar/20.svg";
        percentage = 20;
        break;
      case "forty":
        progressBarSrc = "/progressbar/40.svg";
        percentage = 40;
        break;
      case "sixty":
        progressBarSrc = "/progressbar/60.svg";
        percentage = 60;
        break;
      case "eighty":
        progressBarSrc = "/progressbar/80.svg";
        percentage = 80;
        break;
      case "ninetynine":
        progressBarSrc = "/progressbar/99.svg";
        percentage = 99;
        break;
      case "hundred":
        progressBarSrc = "/progressbar/100.svg";
        percentage = 100;
        break;

      default:
        break;
    }
    return (
      <div className="container-v" style={{ width: "100%", gap: "15px" }}>
        <div className="container" style={{ justifyContent: "space-between" }}>
          <div className="quest-intruction-text">
            <b>ВОССТАНОВЛЕНИЕ ДАННЫХ...</b>
          </div>
          <div className="quest-intruction-text">
            <b>{percentage}%</b>
          </div>
        </div>
        <img src={progressBarSrc} alt={step} />
      </div>
    );
  };

  const getInstruction = (step: string) => {
    let instruction = <div></div>;
    switch (step) {
      case "zero":
        instruction = (
          <div className="quest-intruction-text">
            <b>Избранный</b>, для начала нужно вооружиться киберимплантами! Тебе
            срочно нужно <span className="quest-accent">найти завод</span> на
            нашем фестивале!
          </div>
        );
        break;
      case "twenty":
        instruction = (
          <div className="quest-intruction-text">
            <b>Избранный</b>, начало положено, но неполадки обнаружены в{" "}
            <span className="quest-accent">игротеке наших партнеров</span>, им
            нужна твоя помощь!
          </div>
        );
        break;
      case "forty":
        instruction = (
          <div className="quest-intruction-text">
            <b>Избранный</b>, ты на верном пути и смело проходишь все
            препятствия, но в наших кибер-механизмах найдено необычное скопление
            меха... <br />
            Hедавно наши нано-инженеры очищали матрицы от{" "}
            <span className="quest-accent">ежей и рогов</span>!
          </div>
        );
        break;
      case "sixty":
        instruction = (
          <div className="quest-intruction-text">
            Кажется у нас слетела кодировка…
            <br />
            <br />
            <span className="quest-accent">Өфө косплей барахолкаһы</span> иң
            яҡшы урын был ҡалала!
          </div>
        );
        break;
      case "eighty":
        instruction = (
          <div className="quest-intruction-text">
            <b>Избранный</b>, данные в кибер-реальности почти восстановлены, но
            что-то снова пошло не так… Наши спутники ловят странные сигналы{" "}
            <span className="quest-accent">
              <br />
              ..электро <br />
              ..пиро <br />
              ..гео <br />
              ..дендро <br />
              ..анемо
              <br />
              ..крио
              <br />
              ..гидро?! <br />
            </span>
            Что это значит?! Кто передает эти сигналы и мешает загрузить
            оставшиеся данные?
          </div>
        );
        break;
      case "ninetynine":
        instruction = (
          <div className="quest-intruction-text">
            <b>Избранный</b>, знать путь и пройти его - не одно и то же, пора
            освободить свой разум, но один ты не справишься! Тебе нужна помощь{" "}
            <span className="quest-accent">фестиваля NYAF</span>!
          </div>
        );
        break;
      case "hundred":
        instruction = (
          <div className="quest-intruction-text">
            Мы верили в тебя, <b>Избранный</b>! Данные восстановлены,
            кибер-реальность NYAF спасена и мы не останемся в долгу! <br />{" "}
            <br />
            Hо будь настороже, поговаривают, что токсины из кибер-пространства
            уже начали захватывать и другие реальности...
          </div>
        );
        break;

      default:
        break;
    }

    return instruction;
  };

  const getContent = () => {
    if (showContent) {
      if (stepError) {
        return getError();
      }
      if (id === "start") {
        return getStart();
      }
      if (id === "init") {
        return getInit();
      }
      if (id === "last") {
        return getLast();
      }
      return getStep();
    }
    return <></>;
  };

  const getTab = (type: "quest" | "map") => {
    switch (type) {
      case "quest":
        return (
          <div
            className={`tab ${tab === "quest" ? "selected-tab" : ""}`}
            onClick={() => setTab("quest")}
          >
            КВЕСТ NYAF 2024: КИБЕРПАНК
          </div>
        );
      case "map":
        return (
          <div
            className={`tab ${tab === "map" ? "selected-tab" : ""}`}
            onClick={() => setTab("map")}
          >
            <img
              className="schedule-like"
              src={tab === "map" ? "/white-fest-map.svg" : "/fest-map.svg"}
              alt={"map"}
            />
          </div>
        );

      default:
        return <></>;
    }
  };

  const getMap = () => {
    return (
      <div className="container-v">
        <img src="/fest-map-min.jpg" alt="NYAF" />
      </div>
    );
  };

  return (
    <div className="quest-page">
      <div className="container" style={{ width: "100%" }}>
        {getTab("quest")}
        {getTab("map")}
      </div>
      {/* <h1 className="quest-header">КВЕСТ NYAF 2024: КИБЕРПАНК</h1> */}

      {!isAccessTokenValid() ? (
        <div className="quest-body">
          <h2
            className="schedule-item-title container center"
            style={{ margin: "15px", textAlign: "center", color: "white" }}
          >
            Для участия в квесте необходимо авторизоваться на сайте!
          </h2>
        </div>
      ) : (
        <>
          {isLoading ? (
            <div className="loading-overlay" style={{ position: "absolute" }}>
              <div className="spinner"></div>
            </div>
          ) : (
            <>
              {tab === "quest" && (
                <div className="quest-body">{getContent()}</div>
              )}
              {tab === "map" && getMap()}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Quest;
