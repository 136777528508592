import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import Admin from "./admin/Admin";
import SiteContent from "./admin/siteContent/SiteContent";
import Deadlines from "./admin/siteContent/Deadlines";
import Nominations from "./admin/nominations/Nominations";
import InfoPage from "./admin/siteContent/Info";
import NominationsPage from "./admin/nominations/NominationsPage";
import About from "./pages/About";
import Main from "./pages/Main";
import Info from "./pages/Info";
import Contest from "./pages/Contest";
import Hall from "./pages/Hall";
import PartnersSection from "./components/Sections/PartnersSection";
import Tickets from "./pages/Tickets";
import MyApplications from "./pages/MyApplications";
import Apply from "./pages/Apply";
import LoginFromVk from "./components/LoginFromVk";
import EmailConfirmation from "./pages/EmailConfirmation";
import FieldsPage from "./admin/nominations/FieldsPage";
import SchedulePage from "./pages/SchedulePage";
import Profile from "./pages/Profile";
import NominationsFields from "./admin/nominations/NominationsFields";
import { isAdmin } from "./utils/authUtils";
import NotAuthorizedPage from "./pages/NotAuthorizedPage";
import Applications from "./admin/applications/Applications";
import ApplicationsPage from "./admin/applications/ApplicationsPage";
import ApplicationsSettings from "./admin/applications/ApplicationsSettings";
import AllApplications from "./pages/AllApplications";
import BlocksPage from "./admin/schedule/BlocksPage";
import Schedule from "./admin/schedule/Schedule";
import ScheduleExports from "./admin/schedule/ScheduleExports";
import VotingPage from "./pages/VotingPage";
import Users from "./admin/users/Users";
import Applicants from "./admin/users/Applicants";
import DanceApplications from "./admin/users/DanceApplications";
import OnlineVoteAdminPage from "./admin/applications/OnlineVoteAdmin";
import UserVoteAdmin from "./admin/applications/UserVoteAdmin";
import AnyPage from "./pages/AnyPage";
import BuyTicket from "./pages/BuyTicket";
import Quest from "./pages/Quest";
import AuthorsApplications from "./admin/users/AuthorsApplications";
import FairApplications from "./admin/users/FairApplications";
import ExportAllApplications from "./admin/users/ExportAllApplications";
import ScheduleWithPictures from "./pages/ScheduleWithPictures";

function App() {
  function AdminRoute({ element }: { element: JSX.Element }) {
    if (isAdmin()) {
      return element;
    } else {
      return <Navigate to="/" replace />;
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="what-where-when" element={<Info />} />
          <Route path="faq" element={<Info />} />
          <Route path="rules" element={<Info />} />
          <Route
            path="partners"
            element={
              <PartnersSection heightStyle="longread contest content-section" />
            }
          />
          <Route path="tickets" element={<Tickets />} />
          <Route path="contest/:id" element={<Contest />} />
          <Route path="quest/:id" element={<Quest />} />
          <Route
            path="all-applications"
            element={<AllApplications type="scene" />}
          />
          <Route
            path="all-applications-hall"
            element={<AllApplications type="hall" />}
          />
          <Route path="hall/:id" element={<Hall />} />
          <Route path="my-applications" element={<MyApplications />} />
          <Route path="apply" element={<Apply />} />
          <Route path="/loginFromVk" element={<LoginFromVk />} />
          <Route path="confirmEmail" element={<EmailConfirmation />} />
          <Route path="schedule" element={<SchedulePage />} />
          <Route path="schedule-orgs" element={<ScheduleWithPictures />} />
          <Route path="my-profile" element={<Profile />} />
          <Route
            path="photocosplay/vote"
            element={<VotingPage voteType="photo" />}
          />
          <Route path="pet/vote" element={<VotingPage voteType="pet" />} />
          <Route path="edits/vote" element={<VotingPage voteType="edit" />} />
          {/* <Route
            path="buy-ticket/normal"
            element={<BuyTicket type="normal" />}
          />
          <Route path="buy-ticket/vip" element={<BuyTicket type="vip" />} /> */}
          <Route path="*" element={<AnyPage />} />
        </Route>

        <Route path="admin" element={<AdminRoute element={<Admin />} />}>
          <Route
            path="site-content"
            element={<AdminRoute element={<SiteContent />} />}
          >
            <Route
              path="deadlines"
              element={<AdminRoute element={<Deadlines />} />}
            />
            <Route
              path="info"
              element={<AdminRoute element={<InfoPage />} />}
            />
          </Route>
          <Route
            path="nominations"
            element={<AdminRoute element={<Nominations />} />}
          >
            <Route
              path="nominations"
              element={<AdminRoute element={<NominationsPage />} />}
            />
            <Route
              path="fields"
              element={<AdminRoute element={<FieldsPage />} />}
            />
            <Route
              path="nominationfields"
              element={<AdminRoute element={<NominationsFields />} />}
            />
          </Route>
          <Route
            path="applications"
            element={<AdminRoute element={<Applications />} />}
          >
            <Route
              path="applications"
              element={<AdminRoute element={<ApplicationsPage />} />}
            />
            <Route
              path="settings"
              element={<AdminRoute element={<ApplicationsSettings />} />}
            />
            <Route
              path="photocosplay-admin"
              element={<AdminRoute element={<OnlineVoteAdminPage />} />}
            />
            <Route
              path="uservote-admin"
              element={<AdminRoute element={<UserVoteAdmin />} />}
            />
          </Route>
          <Route
            path="schedule"
            element={<AdminRoute element={<Schedule />} />}
          >
            <Route
              path="blocks"
              element={<AdminRoute element={<BlocksPage />} />}
            />
            <Route
              path="schedule"
              element={<AdminRoute element={<ScheduleExports />} />}
            />
          </Route>
          <Route path="users" element={<AdminRoute element={<Users />} />}>
            <Route
              path="applicants"
              element={<AdminRoute element={<Applicants />} />}
            />
            <Route
              path="applications"
              element={<AdminRoute element={<ExportAllApplications />} />}
            />
            <Route
              path="dance"
              element={<AdminRoute element={<DanceApplications />} />}
            />
            <Route
              path="authors"
              element={<AdminRoute element={<AuthorsApplications />} />}
            />
            <Route
              path="fair"
              element={<AdminRoute element={<FairApplications />} />}
            />
          </Route>
        </Route>

        <Route path="/not-authorized" element={<NotAuthorizedPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
