import React, { ReactNode, useEffect, useState } from "react";
import "./VotingPage.css";
import { UserVote } from "../admin/types";
import {
  getVotesForUser,
  setPhotocosplayRating,
} from "../services/applicationsService";
import VotePreviewWithPopUp from "../components/VotePreviewWithPopUp";

export type VoteType = "photo" | "pet" | "edit";

function VotingPage({ voteType }: { voteType: VoteType }) {
  const [userVotesData, setUserVotesData] = useState<UserVote[]>();

  useEffect(() => {
    async function fetchPage() {
      try {
        const photocosplayData = await getVotesForUser(voteType);
        setUserVotesData(
          photocosplayData.sort((a, b) =>
            a.applicationId.localeCompare(b.applicationId)
          )
        );
      } catch (error) {
        // Handle error
        console.error("Error fetching pages:", error);
      }
    }

    fetchPage();
  }, [voteType]);

  const getVotingCard = (voteData: UserVote): ReactNode => {
    return (
      <VotePreviewWithPopUp
        type={voteType}
        userVoteData={voteData}
        ratingChanged={ratingChanged}
      />
    );
  };

  const ratingChanged = async (applicationId: string, newRating: number) => {
    const photocosplay = userVotesData?.find(
      (d) => d.applicationId === applicationId
    );
    if (photocosplay && userVotesData) {
      photocosplay.rating = newRating;

      setUserVotesData(
        [
          ...userVotesData.filter((f) => f.applicationId !== applicationId),
          photocosplay,
        ].sort((a, b) => a.applicationId.localeCompare(b.applicationId))
      );
    }
    await setPhotocosplayRating(applicationId, newRating);
  };

  return (
    <div className="contest longread">
      {userVotesData ? (
        <div className="content-section container-v center">
          <div
            className="content longread-content"
            style={{ paddingTop: "0px" }}
          >
            {voteType === "photo" && <h1>ФОТОКОСПЛЕЙ</h1>}
            {voteType === "pet" && <h1>Pet-КОСПЛЕЙ</h1>}
            {voteType === "edit" && <h1>КОНКУРС ЭДИТОВ</h1>}
            <h2>Голосование</h2>
            <div
              className="container photocosplay-page"
              style={{ width: "100%", marginTop: "20px" }}
            >
              {userVotesData.map((voteData) => {
                return getVotingCard(voteData);
              })}
            </div>
            ;
          </div>
        </div>
      ) : (
        <div style={{ height: "100vh" }}>
          <div className="loading-overlay">
            <div className="spinner"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default VotingPage;
