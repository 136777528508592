// pageService.ts

import { BASE_URL } from "../config";
import { fetchWithAuth } from "../utils/authUtils";

// Helper function to handle fetch errors
const handleFetchError = (error: any) => {
  console.error("API Request Failed:", error);
};

export async function getTicketNumber(): Promise<number> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/tickets/get_ticket_number`
    );

    if (!response.ok) {
      throw new Error(`GET Request Failed with status ${response.status}`);
    }

    const data = await response.json();
    return data as number;
  } catch (error) {
    handleFetchError(error);
    return Promise.reject(error);
  }
}

export async function assignTicketNumber(body: {
  ticketNumber: number;
}): Promise<number | string> {
  try {
    const response = await fetchWithAuth(
      `${BASE_URL}/tickets/assign_ticket`,
      "POST",
      body
    );

    if (!response.ok) {
      if (response.status === 400) {
        const responseText = await response.text();
        return responseText;
      } else {
        throw new Error(`POST Request Failed with status ${response.status}`);
      }
    } else {
      const data = await response.json();
      return data as number;
    }
  } catch (error) {
    handleFetchError(error);
    return Promise.reject(error);
  }
}
